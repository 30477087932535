/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../compontents/Layout/Layout';

type WebDevelopmentPageProps = {
    location: any;
};

const WebDevelopmentPage = ({ location }: WebDevelopmentPageProps) => {
    return (
        <Layout location={location}>
            <div data-testid="web-development-container">
                <Helmet>
                    <title>Custom Website Development: Get a Site Designed Just for You</title>
                </Helmet>

                {/*Intro Header*/}
                <div className="container">
                    <div className="row pt-5 center">
                        <div className="col-lg-6 align-self-center">
                            <h1 className="mb-0">
                                <span style={{ fontSize: '1.5rem' }}>Elevate Your Online Presence with</span>
                                <br />
                                Custom Website Development
                            </h1>
                            <p className="mainText mb-4">Get a website that truly meets your needs.</p>
                            <a className="button-primary" href="/contact-us">
                                Contact us to get started
                            </a>
                        </div>
                        <div className="col-lg-6">
                            <img className="img-fluid" src="../../images/web-development/web-design-layers.gif" />
                        </div>
                    </div>
                </div>
                {/*end Intro Header*/}

                {/*Struggle section*/}
                <div className="bg-gray py-5">
                    <div className="container mt-3">
                        <div className="row">
                            <h3>Solving Common Website Struggles</h3>
                            <div className="col-lg-4 col-sm-8 mx-auto">
                                <img className="img-fluid" src="../../images/web-development/bad-web-design.png" />
                            </div>
                            <div className="col-lg-8">
                                <p className="mt-3">
                                    Are you struggling with your current website? We hear about many of the same
                                    challenges when it comes to a business’s online presence:{' '}
                                    <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                                        Poor user experience (UX), outdated design, and limited functionality
                                    </span>
                                    .
                                </p>
                                <p>
                                    A website that is difficult to navigate, slow, or does not display well on mobile
                                    screens can drive visitors away. An outdated design can also negatively impact your
                                    brand image and make your company seem less professional.
                                </p>
                                <p>
                                    <strong>Partner with SquareHook</strong> to say goodbye to generic templates and
                                    limited functionality. and get a website solution{' '}
                                    <strong>custom-made for what you need</strong>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* endStruggle section*/}

                {/*Solutions*/}
                <div className="container mt-5">
                    <div className="prose">
                        <div className="pt-3 mb-3">
                            <h3 className="mb-0">Expert Website Development and Hosting Services</h3>
                        </div>
                        <p>
                            At SquareHook we started our legacy helping clients with website development and hosting,
                            and we continue that essential service now. Our tech-savvy development team has a history of
                            delivering high-quality, functional websites and software.
                        </p>
                        <p>
                            Whether you just need a basic website or require complex solutions for your enterprise-level
                            site, we specialize in creating user-friendly websites that{' '}
                            <strong>drive engagement and conversions.</strong>
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <h5>Ongoing Support and Maintenance for Your New Site</h5>
                            <p>
                                At SquareHook, we don't just deliver a custom website and walk away. We can offer
                                ongoing support and maintenance to ensure your website continues to bring you success.
                                From bug fixes to new features and even training,{' '}
                                <strong>our team is here for you.</strong>
                            </p>
                        </div>
                        <div className="col-lg-4 col-sm-8 mx-auto">
                            <img className="img-fluid" src="../../images/web-development/good-web-design.png" />
                        </div>
                    </div>
                </div>
                {/* end solutions */}

                {/* benefits of Custom  */}

                <div id="custom-websites" className="container mt-5">
                    <div className="prose">
                        <h2>Your business is unique. Your website should be too.</h2>
                        <h5>
                            Say Goodbye to Pre-built Templates and Hello to Scalability, Flexibility, and Advanced
                            Functionality.
                        </h5>
                        <p>
                            With current web technologies and the needs of businesses constantly evolving, you’re going
                            to need something that the cookie-cutter website builders can’t give you. You need a custom
                            solution tailored to your exact requirements.
                        </p>
                        <p>
                            Custom website development offers numerous benefits over template-based website builders,
                            such as complete control over design and functionality, unique branding, and advanced
                            features. With custom development, you'll be able to create a user-friendly experience for
                            your visitors that drives engagement and conversions.
                        </p>
                    </div>
                    <div className="prose mx-auto mt-5">
                        <h4 className="center">Some of the Benefits of Getting a Custom Website for Your Company:</h4>
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseOne">
                                        Unique Design
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseOne"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <p>
                                            A custom website can be designed from scratch set you apart from others and
                                            reflect your unique brand.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingTwo">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwo">
                                        Improved functionality
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseTwo"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingTwo"
                                    data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <p>
                                            Custom websites can be built with advanced functionality, such as e-commerce
                                            functions, custom forms, custom plugins, and any other custom thing you need
                                            to meet the specific requirements of your business.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingThree">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThree"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThree">
                                        Scalability
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseThree"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingThree"
                                    data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <p>
                                            Your website will be built to grow with your business, so you don't have to
                                            worry about being limited by pre-set templates. Functionality and features
                                            can be added or changed as your business evolves.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingFour">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFour"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFour">
                                        Flexible Developers
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseFour"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingFour"
                                    data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <p>
                                            When you partner with SquareHook, you get a team that is flexible enough to
                                            adapt to changing requirements and timelines during the development process.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingFive">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFive"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFive">
                                        Ownership
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseFive"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingFive"
                                    data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <p>
                                            With a custom website, you own all the code and design, so you have complete
                                            control over your website and its future.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end benefits of custom */}

                {/* collaboration */}

                <div className="container mt-5">
                    <div className="row pt-4">
                        <div className="col-md-7">
                            <h3>Communication and Collaboration</h3>
                            <p>
                                At SquareHook, we believe in open communication and collaborating with you through every
                                step of the way. Our development team is{' '}
                                <strong>transparent about pricing, project progression, and final results.</strong> We
                                believe in active listening and strive to understand your unique needs and goals,
                                creating a website that truly brings your vision to life.
                            </p>
                        </div>
                        <div className="col-md-5 col-lg-4 center">
                            <img
                                style={{ maxHeight: '250px' }}
                                className="img-fluid"
                                src="../../images/web-development/support-tie.png"
                            />
                            <p className="primary" style={{ fontStyle: 'italic' }}>
                                With you through every step
                            </p>
                        </div>
                    </div>
                </div>
                {/* end collaboration */}

                {/*CTA section*/}
                <div className="bgsection mt-5 p-5">
                    <div className="container mb-5">
                        <h2>Get IT Started</h2>
                        <p>
                            At SquareHook, we know that your website is your business's digital storefront and it's
                            important to make a great first impression. Don't let pre-built templates or limited
                            functionality hold you back. Let us bring your vision to life with custom web development.
                        </p>
                        <h4>Get started with a custom website today</h4>
                        <ol>
                            <li>
                                Contact us for a <strong>FREE consultation</strong>, and we’ll discuss your needs and
                                goals.
                            </li>
                            <li>We'll offer a proposal tailored to your specific requirements and budget.</li>
                            <li>
                                Our team will work closely with you to bring your vision to life and deliver a website
                                that sets you apart from the competition.
                            </li>
                        </ol>
                        <div className="center mainText col-lg-8 mx-auto">
                            <p className="emphasis">
                                So, contact us today for a <strong>free consultation</strong> and take the first step
                                towards a website that truly represents your business.
                            </p>
                            <a className="button-primary" href="/contact-us">
                                Contact Us
                            </a>
                        </div>
                    </div>
                </div>
                {/*end CTA section*/}
            </div>
        </Layout>
    );
};

export default WebDevelopmentPage;
